.color-nav {
    background-color: rgb(214, 163, 54);
}

.navcontainer {
    max-width: 1170px;
    padding-left: 0px;
}

.navbar-light .navbar-nav .nav-link {
     color: black; 
}

.searchbar {
    margin-top: 15px;
}

.filters {
    margin-top: 20px;
    margin-left: unset;
    margin-right: unset;
}

.noresults {
    text-align: center;
}

.projectscontent {
    margin-top: -10px;
    padding-bottom: 50px;
}

.projectcard {
    min-width: 18rem;
    max-width: 18rem;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;   
}

.button {
    color: #fff;
    background-color: #096ccf;
    border-color: #096ccf;
}

.button:hover, .button:focus, .button:active, .button.active {
    color: #fff;
    background-color: #0c49ce;
    border-color: #0c49ce; /*set the color you want here*/
}

.projcarddeck {
    justify-content: center;
    margin-bottom: 25px;
}

@media(max-width:766px) {
    .filters {
        display: none;
    }

    .col-sm-11 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
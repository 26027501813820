.accordianbutton {
    margin-left: -13px;
}

.skillcard {
    margin-top: 20px;
    border: unset;
    margin-left: auto;
    margin-right: auto;
    transition: transform .2s;
}

.skillcard:hover {
    transform: scale(1.25);
  }

.skillimage {
    max-width: 96px;
}

.skills {
    margin-left: 15px;
    margin-right: 15px;
}
.profile {
    border-radius: 10px;
    border: .5px solid rgba(0,0,0,.125);
}

.profilecol {
    justify-content: center;
    margin-bottom: 15px;
}

.homefooter {
    bottom: 0;
}

.banner {
    width: 100%;
    height: 190px;
    background-image: url("../../assets/hdmountainscropped3.jpg");
    background-size: cover;
}

@media(max-width:766px) {
    .banner {
        height: 150px;
    }
}

@media(max-width:415px) {
    .banner {
        display: none;
    }
}
